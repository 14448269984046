import { Navigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useRef, useState } from "react";


const Protected = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true)
    const isLogin = useRef(false)
    useEffect(() => {
        const token = localStorage.getItem('tkn');
        axios.post('https://panel.apoloribabes.website/auth', { token: token }).then((res) => {
            isLogin.current = res.data.login
            setIsLoading(false)
        })

    }, [])

    if (isLoading) {
        return <div>Loading...</div>;
    }
    return (
        <div>
            {isLogin.current ? children : <Navigate to={'/'} />}
        </div>
    )
};


export default Protected;